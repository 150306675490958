import { Address } from './address';

export interface ContactInformationInterface {
  phone: string;
  phoneExt: string;
  email: string;
  fax: string;
  cellPhone: string;
  shippingAddress: Address;
  billingAddress: Address;
}

export class ContactInformation implements ContactInformationInterface {
  phone: string;
  phoneExt: string = '';
  email: string;
  fax: string;
  cellPhone: string;
  shippingAddress: Address;
  billingAddress: Address;
  [key: string]: any;

  constructor() {
    this.phone = '';
    this.email = '';
    this.fax = '';
    this.cellPhone = '';
    this.shippingAddress = new Address();
    this.billingAddress = new Address();
  }

  buildContactInfo(contactInfo: any) {
    for (var key in contactInfo) {
      if (contactInfo.hasOwnProperty(key)) {
        this[key] = contactInfo[key];
      }
    }
    return this;
  }

  buildContactInfoObj(contactInfo: ContactInformation) {
    for (var key in contactInfo) {
      if (contactInfo.hasOwnProperty(key)) {
        this[key] = contactInfo[key];
      }
    }
    return this.toObj();
  }

  toObj() {
    return {
      phone: this.phone,
      email: this.email,
      fax: this.fax,
      cellPhone: this.cellPhone,
      shippingAddress:
        this.shippingAddress.toObj === undefined
          ? this.shippingAddress
          : this.shippingAddress.toObj(),
      billingAddress:
        this.billingAddress.toObj === undefined
          ? this.billingAddress
          : this.billingAddress.toObj(),
    };
  }
}
