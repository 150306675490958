import { getDocs, Query } from "@angular/fire/firestore";
import { lastValueFrom, map } from "rxjs";
import { eventToFilterArgs, getNextChunk, searchFilter } from "./chunk";

export class GetAllDocs {
	public all: Array<any> = [];
	public allChunk: Array<any> = [];
	public filterargs: string = "";
	public searching: boolean = false;
	private allSearch: Array<any> = [];
	private limit: number = 25;
	private fields: string[] = [];
	public reduceOptions: any = {};
	public reverseChunk: boolean = false;
	constructor(
		fields: string[],
		reverseChunk: boolean = false,
		limit: number = 25
	) {
		this.fields = fields;
		this.reverseChunk = reverseChunk;
		this.limit = limit;
	}

	public async getAll(
		query: Query<any>,
		reduce: (querySnapshot: any, options?: {}) => any,
		reduceOptions = {}
	) {
		this.reduceOptions = reduceOptions;
		try {
			this.searching = true;
			this.allChunk = [];
			let querySnapshot = await getDocs(query);
			// .pipe(
			// 		map((querySnapshot) => {
			this.setAll(reduce(querySnapshot, this.reduceOptions));
			// 	})
			// )
		} catch (error) {
			console.log(error);
		}
	}

	public setAll(all: Array<any>) {
		this.allChunk = [];
		this.all = all;
		this.getAllSearch(this.filterargs);
		this.searching = false;
	}

	public getAllSearch(event: any) {
		this.filterargs = eventToFilterArgs(event, this.filterargs);
		if (this.all.length > 0) {
			if (this.filterargs !== "") {
				this.allSearch = searchFilter(
					this.allSearch,
					this.all,
					this.filterargs,
					this.fields
				);
			}
			this.allChunk = [];
			this.getChunk();
		}
	}

	public getChunk(event: any = null) {
		this.allChunk = getNextChunk(
			event,
			this.filterargs,
			this.allChunk,
			this.all,
			this.allSearch,
			this.limit,
			this.reverseChunk
		);
	}
}
