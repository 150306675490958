import { Injectable } from '@angular/core';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { NavController } from '@ionic/angular';
import { UserService } from './user.service';
import { MenuItem } from '../models/menuItem';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public scrollToViewId: string = '';

  constructor(public navCtrl: NavController, public userService: UserService) {}

  async scroll(to: string) {
    this.scrollToViewId = to;
    let naved = await this.navCtrl.navigateRoot('');
    if (naved === null) {
      this.scrollToView();
    }
  }

  public headerMenuItems(): MenuItem[] {
    let menu: MenuItem[] = [
      new MenuItem('Features', 'features', false),
      new MenuItem('Pricing', 'pricing', true),
      new MenuItem('Help', 'help', false),
    ];
    if (this.userService.loggedIn) {
      menu.push(new MenuItem('Account', 'dashboard', false));
    } else {
      menu.push(new MenuItem('Login', 'login', false));
    }
    return menu;
  }

  public menuItems(): MenuItem[] {
    let menu: MenuItem[] = [
      new MenuItem('Features', 'features', false),
      new MenuItem('Pricing', 'pricing', true),
      new MenuItem('Help', 'help', false),
    ];
    if (this.userService.loggedIn) {
      menu.push(new MenuItem('Account', 'dashboard', false));
    } else {
      menu.push(new MenuItem('Login', 'login', false));
    }
    return menu;
  }

  public featuresMenu(): MenuItem[] {
    let menu: MenuItem[] = [
      new MenuItem('Projects', 'features/projects', false),
      new MenuItem('Estimate', 'features/estimate', false),
      new MenuItem('Schedule', 'features/schedule', false),
      new MenuItem('Invoice', 'features/invoice', false),
      new MenuItem('Client Messaging', 'features/clientMessaging', false),
      new MenuItem('Internal Messaging', 'features/internalMessaging', false),
      new MenuItem('Accounting', 'features/accounting', false),
      new MenuItem('Employee Management', 'features/employeeManagement', false),
    ];
    return menu;
  }

  navigateTo(page: string) {
    if (page === '') {
      this.navCtrl.navigateRoot('');
    } else if (page != '') {
      this.navCtrl.navigateForward([page]);
    }
  }

  scrollToView() {
    try {
      var anchor = document.getElementById(this.scrollToViewId);
      if (anchor) {
        anchor.scrollIntoView({ block: 'start', behavior: 'smooth' });
        anchor.scrollTo();
      }
    } catch (error) {}
    this.scrollToViewId = '';
  }
}
