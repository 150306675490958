import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";

@Injectable({
	providedIn: "root",
})
export class NavigationService {
	constructor(public navCtrl: NavController) {}

	public navigateTo(approvedUser: boolean, page: string, id?: string) {
		let pageStr = "";
		switch (page) {
			case "HomePage":
				pageStr = "home";
				break;
			case "LocationPage":
				pageStr = "location";
				break;
			case "EquipmentPage":
				pageStr = "equipment";
				break;
			case "ProjectPage":
				pageStr = "project";
				break;
			case "AllProjectsPage":
				pageStr = "all-projects";
				break;
			case "AccountPage":
				pageStr = "account";
				break;
			case "ClientEstimatePage":
				pageStr = "clientEstimate";
				break;
			case "OSEPage":
				pageStr = "ose";
				break;
			case "SnowPlowPage":
				pageStr = "snowPlow";
				break;
			case "AssignmentPage":
				pageStr = "assignment";
				break;
			case "TimePage":
				pageStr = "time";
				break;
			case "SummaryPage":
				pageStr = "summary";
				break;
			case "ContactPage":
				pageStr = "contact";
				break;
			case "UserPage":
				pageStr = "user";
				break;
			default:
				pageStr = "";
				break;
		}

		if (pageStr != "" && id != null && id != "") {
			this.navCtrl.navigateForward([pageStr, { id: id }]);
		} else if (pageStr != "") {
			this.navCtrl.navigateForward([pageStr]);
		}
	}

	public async selectUid(approvedUser: boolean, page: string, id: string) {
		let pageStr = "";
		switch (page) {
			case "HomePage":
				pageStr = "home";
				break;
			case "LocationPage":
				pageStr = "location";
				break;
			case "EquipmentPage":
				pageStr = "equipment";
				break;
			case "ProjectPage":
				pageStr = "project";
				break;
			case "AllProjectsPage":
				pageStr = "all-projects";
				break;
			case "AccountPage":
				pageStr = "account";
				break;
			case "OSEPage":
				pageStr = "ose";
				break;
			case "SnowPlowPage":
				pageStr = "snowPlow";
				break;
			case "AssignmentPage":
				pageStr = "assignment";
				break;
			case "ContactPage":
				pageStr = "contact";
				break;
			case "UserPage":
				pageStr = "user";
				break;
			default:
				pageStr = "home";
				break;
		}

		if (pageStr != "" && id != null && id != "") {
			await this.navCtrl.navigateForward([pageStr, { id: id }]);
		}
	}
}
