import { BreakpointState } from '@angular/cdk/layout';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public mobileView: boolean = false;
  constructor(public breakpointObserver: BreakpointObserver) {}
  public determineWindowSize() {
    this.breakpointObserver
      .observe(['(min-width: 450px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = false;
        } else {
          this.mobileView = true;
        }
      });
  }
}
