import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';
import { provideFirestore, initializeFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { HttpClientModule } from '@angular/common/http';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { provideServiceWorker } from '@angular/service-worker';
import { CalendarModule as IonCalendarModule } from 'ion7-calendar';
if (environment.production) {
  enableProdMode();
}
export const firebaseConfig = environment.firebaseConfig;

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(
      IonicModule.forRoot({}),
      provideAuth(() => {
        return getAuth();
      }),
      provideFirebaseApp(() => initializeApp(firebaseConfig)),
      provideFirestore(() => {
        // const firestore = getFirestore();
        const firestore = initializeFirestore(getApp(), {
          experimentalForceLongPolling: true,
        });
        // enableMultiTabIndexedDbPersistence(firestore).then(
        // 	() => resolvePersistenceEnabled(true),
        // 	() => resolvePersistenceEnabled(false)
        // );
        return firestore;
      }),
      provideStorage(() => getStorage(getApp())),
      provideFunctions(() => getFunctions(getApp(), 'us-central1')),
      provideAnalytics(() => getAnalytics(getApp())),
      // initializeAppCheck(getApp(),{
      //   provider: new ReCaptchaEnterpriseProvider(/* reCAPTCHA Enterprise site key */),
      //   isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
      // }),
      HttpClientModule,
      IonCalendarModule.forRoot()
    ),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
});
