export interface TimestampInterface {
  readonly seconds: number;
  readonly nanoseconds: number;
  toDate(): Date;
  toMillis(): number;
  isEqual(other: Timestamp): boolean;
  now(): Timestamp;
  fromDate(date: Date): Timestamp;
  fromMillis(milliseconds: number): Timestamp;
}

export class Timestamp implements TimestampInterface {
  constructor(seconds: number, nanoseconds: number) {
    this.seconds = seconds;
    this.nanoseconds = nanoseconds;
  }

  readonly seconds: number;
  readonly nanoseconds: number;
  toDate(): Date {
    return new Date(this.toMillis());
  }
  toMillis(): number {
    return this.seconds * 1000 + this.nanoseconds / 1e6;
  }
  isEqual(other: Timestamp): boolean {
    return (
      other.seconds === this.seconds && other.nanoseconds === this.nanoseconds
    );
  }

  now(): Timestamp {
    return this.fromMillis(Date.now());
  }
  fromDate = function (date: Date) {
    return new Timestamp(date.getTime() / 1000, 0);
  };
  fromMillis = function (milliseconds: number) {
    var seconds = Math.floor(milliseconds / 1000);
    var nanos = (milliseconds - seconds * 1000) * 1e6;
    return new Timestamp(seconds, nanos);
  };

  fromIonTime(time: any) {
    return new Timestamp(new Date(time).getTime() / 1000, 0);
  }
}

// export class Timestamp(seconds, nanoseconds) {
//     this.seconds = seconds;
//     this.nanoseconds = nanoseconds;
//     if (nanoseconds < 0) {
//         throw new FirestoreError(Code.INVALID_ARGUMENT, 'Timestamp nanoseconds out of range: ' + nanoseconds);
//     }
//     if (nanoseconds >= 1e9) {
//         throw new FirestoreError(Code.INVALID_ARGUMENT, 'Timestamp nanoseconds out of range: ' + nanoseconds);
//     }
//     // Midnight at the beginning of 1/1/1 is the earliest Firestore supports.
//     if (seconds < -62135596800) {
//         throw new FirestoreError(Code.INVALID_ARGUMENT, 'Timestamp seconds out of range: ' + seconds);
//     }
//     // This will break in the year 10,000.
//     if (seconds >= 253402300800) {
//         throw new FirestoreError(Code.INVALID_ARGUMENT, 'Timestamp seconds out of range: ' + seconds);
//     }
// }
// Timestamp.now = function () {
//     return Timestamp.fromMillis(Date.now());
// };
// Timestamp.fromDate = function (date) {
//     return Timestamp.fromMillis(date.getTime()/1000);
// };
// Timestamp.fromMillis = function (milliseconds) {
//     var seconds = Math.floor(milliseconds / 1000);
//     var nanos = (milliseconds - seconds * 1000) * 1e6;
//     return new Timestamp(seconds, nanos);
// };
// Timestamp.prototype.toDate = function () {
//     return new Date(this.toMillis());
// };
// Timestamp.prototype.toMillis = function () {
//     return this.seconds * 1000 + this.nanoseconds / 1e6;
// };
// Timestamp.prototype._compareTo = function (other) {
//     if (this.seconds === other.seconds) {
//         return primitiveComparator(this.nanoseconds, other.nanoseconds);
//     }
//     return primitiveComparator(this.seconds, other.seconds);
// };
// Timestamp.prototype.isEqual = function (other) {
//     return (other.seconds === this.seconds && other.nanoseconds === this.nanoseconds);
// };
// Timestamp.prototype.toString = function () {
//     return ('Timestamp(seconds=' +
//         this.seconds +
//         ', nanoseconds=' +
//         this.nanoseconds +
//         ')');
// };
// return Timestamp;
