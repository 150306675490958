import { forwardRef, Inject, Injectable } from '@angular/core';
import {
  Firestore,
  QueryDocumentSnapshot,
  collection,
  query,
  where,
  orderBy,
  doc,
  getDocs,
  limit,
  setDoc,
} from '@angular/fire/firestore';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, scan } from 'rxjs/operators';
import { User } from '../models/user';
import { GetAllDocs } from './getAllDocs';

import { UserService } from './user.service';
import { SystemLogsheet } from '../models/systemLogsheet';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class SystemLogsheetService {
  logsheets$: Observable<any[]>;
  logsheetsEmployee$: Observable<any[]>;
  allLogsheets: any[] = [];
  logsheetDocs: QueryDocumentSnapshot<any>[];
  logsheetsSearch$: Observable<any[]>;
  logsheetsSearch: Array<any>;
  previousLength: number;
  logsheetsEmployee: any[];
  allSystemLogsSummary: any[];
  logsheetEmployeeDocs: QueryDocumentSnapshot<any>[];
  logsheetsSearchEmployee$: Observable<any[]>;
  logsheetsSearchEmployee: Array<any>;
  previousLengthEmployee: number;
  startDate: Date;
  endDate: Date;
  filterargs: string = '';
  public logsheetCategories: Array<string> = [
    'loginLog',
    'clockInLog',
    'clockOutLog',
    'dwell',
    'alert',
    'geoFence',
    'error',
    'page',
    'modal',
    'updateEvent',
    'upload',
    'location',
    'botChat',
  ];
  public cursor: QueryDocumentSnapshot<any>;
  public returnCursor: Array<QueryDocumentSnapshot<any>> = [];
  public currentCursorIndex: number = 0;
  public cursorEmployee: QueryDocumentSnapshot<any>;
  public deviceInfo: DeviceInfo;
  destroyed$: Subject<boolean> = new Subject();
  destroyedLocLog$: Subject<boolean> = new Subject();
  infiniteDestroy$: Subject<boolean> = new Subject();
  finishedSearching$: Subject<boolean> = new Subject();
  logsheetSearchSubject$: Subject<any> = new Subject();

  public searchLimit: number = 50;
  public infiniteLoading: boolean;
  public searching: boolean = false;

  public startDateLogsheet: Date;
  public endDateLogsheet: Date;
  public middleUid: string = '';
  public infiniUp$: Subject<string> = new Subject();
  logsheetsMapEmployee$: Observable<any[]>;
  logsheetsMapSearchEmployee: any[] = [];
  logsheetsMapEmployee: any[] = [];
  logsheetsMapSearchEmployee$: Observable<any[]>;
  mapDestroyed$: Subject<boolean> = new Subject();
  mapReady$: Subject<boolean> = new Subject();
  public getAllLogsheet: GetAllDocs = new GetAllDocs([
    'appVersion',
    'os',
    'isApp',
    'log',
    'email',
    'name',
    'page',
    'pageName',
    'modal',
    'modalName',
    'type',
    'fenceStatus',
    'browser',
    'model',
    'updateEvent',
    'uploadStatus',
    'uploadName',
    'locationUrl',
    'botChatLog',
    'jsonRes',
  ]);

  public availableCategories: string[] = [
    'loginLog',
    'clockInLog',
    'clockOutLog',
    'dwell',
    'alert',
    'geoFence',
    'error',
    'page',
    'modal',
    'updateEvent',
    'upload',
    'location',
    'botChat',
  ];
  constructor(
    public afs: Firestore,
    @Inject(forwardRef(() => UserService))
    public userService: UserService,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    this.cursor = null;
    this.logsheets$ = new Observable();
    this.logsheetsSearch$ = new Observable();
    this.cursorEmployee = null;
    this.logsheetsEmployee$ = new Observable();
    this.logsheetsMapEmployee$ = new Observable();
    this.logsheetsSearchEmployee$ = new Observable();
    this.logsheetsMapSearchEmployee$ = new Observable();

    this.startDateLogsheet = new Date();
    this.endDateLogsheet = new Date();
    this.startDateLogsheet.setHours(0, 0, 0, 0);
    this.endDateLogsheet.setHours(23, 59, 59, 59);
  }

  setErrorService() {}

  private async addToCollection(log: SystemLogsheet) {
    let newLogsheetdDoc = doc(collection(this.afs, 'logsheets'));
    log.uid = newLogsheetdDoc.id;
    log.dbOriginProjectId = environment.firebaseConfig.projectId;
    await setDoc(newLogsheetdDoc, log.toObj());
  }

  loginLog(
    appVersion: string,
    isApp: boolean,
    email,
    dateCreated: Date,
    log: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.log = log;
    sysLog.type = 'loginLog';

    this.addToCollection(sysLog);
  }

  clockInLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    dateModified: Date,
    log: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.type = 'clockInLog';

    this.addToCollection(sysLog);
  }

  clockOutLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    dateModified: Date,
    log: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.type = 'clockOutLog';

    this.addToCollection(sysLog);
  }

  dwellLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    dateModified: Date,
    log: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.type = 'dwell';

    this.addToCollection(sysLog);
  }

  alertLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    dateModified: Date,
    log: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.type = 'alert';

    this.addToCollection(sysLog);
  }

  geoFenceLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    dateModified: Date,
    log: string,
    fenceStatus: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.fenceStatus = fenceStatus;
    sysLog.type = 'geoFence';

    this.addToCollection(sysLog);
  }

  errorLog(appVersion: string, isApp: boolean, name, email, log: string) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.type = 'error';

    this.addToCollection(sysLog);
  }

  pageLog(appVersion: string, isApp: boolean, name, email, page, pageName) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.page = page;
    sysLog.pageName = pageName;
    sysLog.type = 'page';

    this.addToCollection(sysLog);
  }

  modalLog(appVersion: string, isApp: boolean, name, email, modal, modalName) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.modal = modal;
    sysLog.modalName = modalName;
    sysLog.type = 'modal';

    this.addToCollection(sysLog);
  }

  public createUpdateEventLog(changeObj: any, obj: any, modelName: string) {
    delete changeObj.dateModified;
    this.updateEventLog(
      this.userService.appVersion,
      this.userService.isApp,
      this.userService.loggedInUser.firstName,
      this.userService.loggedInUser.email,
      `${modelName} - ${obj.name}`,
      changeObj
    );
  }

  public createAddEventLog(changeObj: any, obj: any, modelName: string) {
    delete changeObj.dateModified;
    this.updateEventLog(
      this.userService.appVersion,
      this.userService.isApp,
      this.userService.loggedInUser.firstName,
      this.userService.loggedInUser.email,
      `CREATED - ${modelName} - ${obj.name}`,
      changeObj
    );
  }

  updateEventLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    model,
    updateEvent
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.updateEvent = updateEvent;
    sysLog.type = 'updateEvent';

    this.addToCollection(sysLog);
  }

  uploadLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    status,
    uploadName
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.uploadStatus = status;
    sysLog.uploadName = uploadName;
    sysLog.type = 'upload';

    this.addToCollection(sysLog);
  }

  locationLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    log: string,
    locationURL: string
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.log = log;
    sysLog.locationUrl = locationURL;
    sysLog.type = 'location';

    this.addToCollection(sysLog);
  }

  botChatLog(
    appVersion: string,
    isApp: boolean,
    name,
    email,
    botChatLog,
    json
  ) {
    let sysLog = new SystemLogsheet();
    sysLog.appVersion = appVersion;
    sysLog.isApp = isApp;
    sysLog.os = this.deviceInfo.os;
    sysLog.browser = this.deviceInfo.browser;
    sysLog.email = email;
    sysLog.name = name;
    sysLog.botChatLog = botChatLog;
    sysLog.jsonRes = json;
    sysLog.type = 'botChat';

    this.addToCollection(sysLog);
  }

  async getLatestLocationLog(email: string) {
    return await getDocs(
      query(
        collection(this.afs, 'logsheets'),
        where('email', '==', email),
        orderBy('dateModified', 'desc'),
        orderBy('locationLog'),
        limit(1)
      )
    );
  }

  async getLatestBackgroundLocationLog(email: string) {
    return await getDocs(
      query(
        collection(this.afs, 'logsheets'),
        where('email', '==', email),
        orderBy('dateModified', 'desc'),
        orderBy('alertLog'),
        limit(1)
      )
    );
  }

  filterByName(items: any[], filter: string) {
    return items.filter((item) => {
      if (
        item.name &&
        item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      ) {
        return item;
      }
    });
  }

  filterByEmail(items: any[], filter: string) {
    return items.filter((item) => {
      if (
        item.email &&
        typeof item.email === 'string' &&
        item.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      ) {
        return item;
      }
    });
  }

  filterByCategory(items: any[]) {
    let categories = new Set(this.logsheetCategories);

    return items.filter((item) => {
      if (categories.has(item.type)) {
        return true;
      }

      return false;
    });
  }

  setLogsheetCategoriesForLogs() {
    this.logsheetCategories = [
      'loginLog',
      'clockInLog',
      'clockOutLog',
      'error',
      'page',
      'modal',
      'updateEvent',
      'upload',
      'botChat',
    ];
  }

  setLogsheetCategoriesForAll() {
    this.logsheetCategories = [
      'loginLog',
      'clockInLog',
      'clockOutLog',
      'dwell',
      'alert',
      'geoFence',
      'error',
      'page',
      'modal',
      'updateEvent',
      'upload',
      'location',
      'botChat',
    ];
  }

  setAllLogsheetQuery(projectId: string) {
    return query(
      collection(this.afs, 'logsheets'),
      where('dbOriginProjectId', '==', projectId),
      where('dateModified', '>=', this.startDateLogsheet),
      where('dateModified', '<=', this.endDateLogsheet),
      orderBy('dateModified', 'desc')
    );
  }

  reduceLogs(querySnapshot?: any, options?: any) {
    console.log('🌲 SYSTEM LOGSHEET', querySnapshot.size);
    return querySnapshot.docs.reduce((arr, doc) => {
      let log = new SystemLogsheet().buildModel(doc.data() as SystemLogsheet);
      log.uid = doc.id;
      if (filterByCategory(log) === true) {
        arr.push(log);
      }
      return arr;
    }, []);

    function filterByCategory(item: any): boolean {
      let categories = new Set(options?.logsheetCategories);
      if (categories.has(item.type)) {
        return true;
      }

      return false;
    }
  }

  async getAllSystemLogsheetsForProject(projectId: string) {
    await this.getAllLogsheet.getAll(
      this.setAllLogsheetQuery(projectId),
      this.reduceLogs,
      {
        logsheetCategories: this.logsheetCategories,
      }
    );
  }

  private sortByDate(a: any[]) {
    return a.sort((item1, item2) => {
      return item2.dateCreated.seconds - item1.dateCreated.seconds;
    });
  }
}
