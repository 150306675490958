export interface AddressInterface {
	streetAddress: string;
	unitSuite: string;
	city: string;
	state: string;
	zip: string;
	unit: string;
	geolocation: {
		lat: number;
		lon: number;
	};
}

export class Address implements AddressInterface {
	streetAddress: string;
	unitSuite: string;
	city: string;
	state: string;
	zip: string;
	unit: string;
	geolocation: { lat: number; lon: number };

	constructor() {
		this.streetAddress = "";
		this.unitSuite = "";
		this.city = "";
		this.state = "";
		this.zip = "";
		this.unit = "";
		this.geolocation = { lat: 0, lon: 0 };
	}

	buildAddress(address: Address) {
		for (var key in address) {
			if (address.hasOwnProperty(key)) {
				this[key] = address[key] != undefined ? address[key] : null;
			}
		}
		return this;
	}

	buildAddressObj(address: Address) {
		for (var key in address) {
			if (address.hasOwnProperty(key)) {
				this[key] = address[key];
			}
		}
		return this.toObj();
	}

	toObj() {
		return Object.assign({}, this);
	}
}
