import { get } from 'lodash';

export function getNextChunk(
  event: any,
  filterArgs: string,
  chunk: any[],
  all: any[],
  search: any[],
  limit: number,
  reverseChunk: boolean
): any[] {
  if (filterArgs !== '') {
    chunk = findNextChunk(chunk, search, event, limit, reverseChunk);
    completeEvent(event);
    return chunk;
  }

  chunk = findNextChunk(chunk, all, event, limit, reverseChunk);

  console.log('🍰 Chunk', chunk.length);
  completeEvent(event);
  return chunk;
}

function findNextChunk(
  acc: any[],
  chunkable: any[],
  event: any,
  limit: number,
  reverseChunk: boolean
) {
  let lastValueIndex = 0;
  if (acc.length !== 0) {
    lastValueIndex = reverseChunk
      ? chunkable.findIndex((inv) => inv.uid === acc[0].uid)
      : chunkable.findIndex((inv) => inv.uid === acc[acc.length - 1].uid);
    if (lastValueIndex === chunkable.length - 1) {
      completeEvent(event);
      return acc;
    }
    if (reverseChunk) {
      let chunk = chunkable.slice(
        lastValueIndex + 1,
        lastValueIndex + 1 + limit
      );
      chunk.reverse();
      return chunk.concat(acc);
    }
    return acc.concat(
      chunkable.slice(lastValueIndex + 1, lastValueIndex + 1 + limit)
    );
  }

  if (reverseChunk) {
    return chunkable
      .slice(lastValueIndex, lastValueIndex + 1 + limit)
      .reverse()
      .concat(acc);
  }
  return acc.concat(
    chunkable.slice(lastValueIndex, lastValueIndex + 1 + limit)
  );
}

function completeEvent(event: any) {
  event !== null ? event.target.complete() : '';
}

function itemFilter(item: any, fieldName: string, filterarg: string) {
  let field = get(item, fieldName);

  if (
    field !== null &&
    field !== undefined &&
    field.toString().toLowerCase().indexOf(filterarg.toLowerCase()) !== -1
  ) {
    return true;
  }
  return false;
}

export function searchFilter(
  results: any[],
  searchable: any[],
  filterargs: string,
  fields: string[]
) {
  const allArgs = filterargs.trim().split(' ');
  results = searchable.reduce((arr: any[], item: any) => {
    let count = 0;
    allArgs.forEach((arg) => {
      fields.forEach((field) => {
        count = itemFilter(item, field, arg) ? count + 1 : count;
      });
    });
    if (count >= allArgs.length) {
      arr.push(item);
    }

    return arr;
  }, new Array<any>());
  return results.filter((elem, pos, arr) => {
    return arr.indexOf(elem) == pos;
  });
}

export function eventToFilterArgs(event: any, filterargs: string) {
  if (event && event.target) {
    filterargs = event.target.value;
  } else if (event) {
    filterargs = event;
  }
  return filterargs;
}
