import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class InternalAuthGuardService implements CanActivate {
  constructor(public _authService: UserService, public _router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot // : Observable<boolean> | Promise<boolean> | boolean
  ) {
    this._authService.requestedRoute = window.location.pathname;

    if (this._authService.loggedIn) {
      console.log('INTERNAL AUTH SERVICE LOGGED IN');
      return true;
    } else if (
      await this._authService.isUserLoggedIn()
      // &&
      // ["admin", "payrollManager", "payroll", "manager", "employee"].indexOf(
      // 	this._authService.loggedInUser.role
      // ) > -1
    ) {
      console.log('INTERNAL AUTH SERVICE IS USER LOGGED IN');
      this._authService.refreshRequestedRoute = window.location.pathname;

      return true;
    }

    // navigate to login page
    console.log('INTERNAL AUTH SERVICE NAVIGATING LOGIN');
    this._router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }
}
